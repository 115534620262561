// THIS IS PART OF the subscription mixin, rest of the code still needs to be transfered
export function useSubscription() {
  const { $error, $toast, $sentryCaptureException, $label, $store } =
    useNuxtApp()
  const isRequestingDowngrade = ref(false)

  function handleCartError(error: any) {
    const errorLabel = $error.getLabel(error)
    const isCartValidationError = CART_VALIDATION_ERRORS.includes(errorLabel)
    const errorMessage = isCartValidationError
      ? errorLabel
      : 'messages.errorOccuredMessage'

    $toast.error($label(errorMessage, undefined), {})
    $sentryCaptureException(error)
  }

  async function downgradeSubscription() {
    try {
      isRequestingDowngrade.value = true

      await $store.dispatch('subscriptions/requestDowngrade')
      await navigateTo(PAGES.subscriptionChangeOverview)
    } catch (error) {
      handleCartError(error)
    } finally {
      isRequestingDowngrade.value = false
    }
  }

  function isSubscriptionUpdate(paymentType: string) {
    const freePayments = [
      CART_PAYMENT_TYPES.subscriptionUpdateFreePayment,
      CART_PAYMENT_TYPES.subscriptionUpdateVerificationPayment,
      CART_PAYMENT_TYPES.subscriptionUpdateFreePaymentAndRefund,
    ]

    return freePayments.includes(paymentType)
  }

  return {
    isRequestingDowngrade,
    downgradeSubscription,
    isSubscriptionUpdate,
  }
}
